const _ = require('lodash');

export const sortCourseByDate = coursesArray => coursesArray?.results?.sort(
  (a, b) => new Date(b.start) - new Date(a.start),
).slice(0, 2);

export const chunkArray = (arr, chunkSize) => _.chunk(arr, chunkSize);

// eslint-disable-next-line max-len
export const calcPercent = (incompleteCount, completeCount) => Math.floor((completeCount / (completeCount + incompleteCount)) * 100);
