import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from '@edx/frontend-platform/i18n';
import PropTypes from 'prop-types';

import { fetchCourses } from '../data/thunks';
import { fetchStartedCourses, fetchWishlist } from '../data/courseSlice';
import Hero from '../Components/home-page/hero-section/Hero';
import AllCourses from '../Components/home-page/all-courses/AllCourses';
import SignupBanner from '../Components/generic/sign-up-banner/SignupBanner';
import StartedCourses from '../Components/home-page/started-courses/StartedCourses';

const HomePage = ({ isLoggedIn }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchStartedCourses());
    dispatch(fetchWishlist());
  }, []);

  const { courses, startedCourses, likedCourses } = isLoggedIn
    ? useSelector((state) => state.courses)
    : { courses: [], startedCourses: [], likedCourses: [] };

  return (
    <main>
      <Hero isLoggedIn={isLoggedIn} courses={courses} likedCourses={likedCourses} />
      {isLoggedIn && startedCourses.length > 0 && <StartedCourses courses={startedCourses} />}
      <AllCourses courses={courses} likedCourses={likedCourses} />
      <SignupBanner isLoggedIn={isLoggedIn} />
    </main>
  );
};
HomePage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default injectIntl(HomePage);
