import { getHttpClient, getAuthenticatedHttpClient } from '@edx/frontend-platform/auth';
import { logError } from '@edx/frontend-platform/logging';
import { camelCaseObject, getConfig } from '@edx/frontend-platform';

const filterAvailableCourses = (now, courses) => courses
  .filter(course => course.start < now && course.end > now);

export async function getCourses() {
  const now = new Date().toJSON();
  const url = `${getConfig().LMS_BASE_URL}/api/courses/v1/courses/?page_size=100`;
  const { data } = await getHttpClient()
    .get(url);
  data.results = filterAvailableCourses(now, data.results);
  return camelCaseObject(data);
}

const getCoursesProgress = (courses) => Promise.all(courses.map(course => getAuthenticatedHttpClient()
  .get(`${getConfig().LMS_BASE_URL}/api/course_home/progress/${course.details.courseId}`)
  .then(resp => ({
    course,
    progress: camelCaseObject(resp.data),
  }))
  .catch(e => logError(e))));

const getCoursesDetails = (courses) => Promise.all(courses.map(course => getAuthenticatedHttpClient()
  .get(`${getConfig().LMS_BASE_URL}/api/courses/v1/courses/${course.course_details.course_id}`)
  .then(resp => ({
    course,
    details: camelCaseObject(resp.data),
  }))
  .catch(e => logError(e))));

const filterStartedCourses = (courses) => courses
  .filter(course => course.progress.completionSummary.incompleteCount > 0);

export const getStartedCourses = () => getAuthenticatedHttpClient()
  .get(`${getConfig().LMS_BASE_URL}/api/enrollment/v1/enrollment`)
  .then(response => response.data)
  .then(data => getCoursesDetails(data))
  .then(courses => getCoursesProgress(courses))
  .then(startedCourses => filterStartedCourses(startedCourses))
  .catch(e => logError(e));

const getFirstBlockOfCourse = (courseBlocksUrl) => getAuthenticatedHttpClient()
  .get(`${courseBlocksUrl}&all_blocks=true`)
  .then(resp => resp.data.root)
  .catch(e => logError(e));

const getBookmarksUrl = () => `${getConfig().LMS_BASE_URL}/api/bookmarks/v1/bookmarks/`;

export function addToWishList(courseId, courseBlocksUrl) {
  getFirstBlockOfCourse(courseBlocksUrl).then((usageId) => getAuthenticatedHttpClient()
    .post(getBookmarksUrl(), {
      course_id: courseId,
      usage_id: usageId,
    }));
}

export function removeFromWishList(username, courseBlocksUrl) {
  getFirstBlockOfCourse(courseBlocksUrl).then((usageId) => getAuthenticatedHttpClient()
    .delete(`${getBookmarksUrl()}${username},${usageId}/`));
}

export async function getLikedCourses() {
  const { data } = await getAuthenticatedHttpClient().get(getBookmarksUrl());
  return camelCaseObject(data.results);
}
