import React from 'react';
import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape, FormattedMessage } from '@edx/frontend-platform/i18n';
import PropTypes from 'prop-types';

import messages from '../../../Pages/messages';
import CourseNews from '../../course-news/CourseNews';
import Video from '../../generic/video-player/Video';

const Hero = ({
  intl, courses, likedCourses, isLoggedIn,
}) => (
  <div className="homepage flex-column">
    <div className="homepage__welcome offset-1 ">
      <h3 className="homepage__welcome_subtitle">
        {intl.formatMessage(messages.welcomeSubtitle)}
      </h3>
      <h2 className="homepage__welcome_title">
        {intl.formatMessage(messages.welcomeTitle)}
      </h2>
      <div className="homepage__welcome_text">
        <p>
          <FormattedMessage
            {...messages.welcomeText}
            values={{
              strong: chunks => <strong>{chunks}</strong>,
            }}
          />
        </p>
        {!isLoggedIn && (
        <>
          <p>
            <FormattedMessage
              {...messages.subscribeMessage}
              values={{
                strong: chunks => <strong>{chunks}</strong>,
              }}
            />
          </p>
          <a
            href={`${getConfig().LMS_BASE_URL}/register`}
            className="button--primary"
          >
            {intl.formatMessage(messages.subscribeButtonText)}
          </a>
        </>
        )}
      </div>
    </div>
    {isLoggedIn ? (
      <div className="homepage__news">
        <h2 className="homepage__courses_category_title">
          {intl.formatMessage(messages.newsTitle)}
        </h2>
        <CourseNews courses={courses} likedCourses={likedCourses} />
      </div>
    ) : (
      <Video source={`${getConfig().WELCOME_MOVIE}`} />
    )}
  </div>
);

Hero.propTypes = {
  intl: intlShape.isRequired,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  likedCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default injectIntl(Hero);
