import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ source }) => (
  <div className="homepage__video">
    <iframe
      title="Video"
      src={source}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  </div>
);

Video.propTypes = {
  source: PropTypes.string.isRequired,
};

export default Video;
