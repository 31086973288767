import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {
  APP_INIT_ERROR, APP_READY, subscribe, initialize, mergeConfig,
} from '@edx/frontend-platform';
import { AppProvider, ErrorPage } from '@edx/frontend-platform/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Header, { messages as headerMessages } from '@edx/frontend-component-header';
import Footer, { messages as footerMessages } from '@edx/frontend-component-footer';
import { messages as courseCardMessages } from '@mediquality/frontend-component-course-card';

import store from './data/store';

import appMessages from './i18n';
import Home from './Pages/Home';
import './assets/Styles/index.scss';

subscribe(APP_READY, () => {
  ReactDOM.render(
    <AppProvider>
      <Provider store={store}>
        <Header />
        <Home />
        <Footer />
      </Provider>
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error) => {
  ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById('root'));
});

initialize({
  handlers: {
    config: () => {
      mergeConfig(
        {
          ACCESS_TOKEN_COOKIE_NAME: process.env.ACCESS_TOKEN_COOKIE_NAME || null,
          CREDENTIALS_BASE_URL: process.env.CREDENTIALS_BASE_URL || null,
          CSRF_TOKEN_API_PATH: process.env.CSRF_TOKEN_API_PATH || null,
          DISCOVERY_API_BASE_URL: process.env.DISCOVERY_API_BASE_URL || null,
          LANGUAGE_PREFERENCE_COOKIE_NAME: process.env.LANGUAGE_PREFERENCE_COOKIE_NAME || null,
          LMS_BASE_URL: process.env.LMS_BASE_URL || null,
          LEARNING_BASE_URL: process.env.LMS_BASE_URL || null,
          LOGO_URL: process.env.LOGO_URL || null,
          LOGO_TRADEMARK_URL: process.env.LOGO_TRADEMARK_URL || null,
          LOGO_WHITE_URL: process.env.LOGO_WHITE_URL || null,
          LOGO_SEARCH_URL: process.env.LOGO_SEARCH_URL || null,
          FAVICON_URL: process.env.FAVICON_URL || null,
          DEFAULT_AVATAR: process.env.DEFAULT_AVATAR || null,
          WELCOME_MOVIE: process.env.WELCOME_MOVIE,
          MY_PROFILE_URL: process.env.MY_PROFILE_URL,
          MY_COURSES_URL: process.env.MY_COURSES_URL,
          MQ_WEBSITE_NEWS_URL: process.env.MQ_WEBSITE_NEWS_URL,
          MQ_WEBSITE_MEDICAL_URL: process.env.MQ_WEBSITE_MEDICAL_URL,
          MQ_WEBSITE_TOPICS_URL: process.env.MQ_WEBSITE_TOPICS_URL,
          MQ_WEBSITE_CONGRESSES_URL: process.env.MQ_WEBSITE_CONGRESSES_URL,
          MQ_WEBSITE_CGU: process.env.MQ_WEBSITE_CGU,
          MQ_WEBSITE_PRIVACY_POLICY: process.env.MQ_WEBSITE_PRIVACY_POLICY,
          MQ_WEBSITE_NEWSLETTERS: process.env.MQ_WEBSITE_NEWSLETTERS,
          ACCOUNT_BASE_URL: process.env.ACCOUNT_BASE_URL,
        },
        'HomeAppConfig',
      );
    },
  },
  messages: [
    appMessages,
    headerMessages,
    footerMessages,
    courseCardMessages,
  ],
});
