/* eslint-disable react/no-danger */
import React from 'react';
import PropType from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { getConfig } from '@edx/frontend-platform';
import messages from '../../messages';

const SignupBanner = ({ intl, isLoggedIn }) => (
  <div className="homepage__sign-in_container">
    <h2 className="homepage__sign-in_title">
      {isLoggedIn ? intl.formatMessage(messages.newsletterTitle) : intl.formatMessage(messages.signupTitle)}
    </h2>
    <p className="homepage__sign-in_text">
      <FormattedMessage
        {...(isLoggedIn ? messages.newsletterText : messages.signupText)}
        values={{
          strong: val => <strong>{val}</strong>,
        }}
      />
    </p>
    <a
      href={isLoggedIn ? getConfig().MQ_WEBSITE_NEWSLETTERS : `${getConfig().LMS_BASE_URL}/register`}
      className="homepage__sign-in_button"
    >
      {intl.formatMessage(messages.signupButtonText)}
    </a>
    {!isLoggedIn && (
    <a
      href={getConfig().LOGIN_URL}
      className="homepage__sign-in_link"
    >
      {intl.formatMessage(messages.signInButtonText)}
    </a>
    )}
  </div>
);

SignupBanner.propTypes = {
  intl: intlShape.isRequired,
  isLoggedIn: PropType.bool.isRequired,
};

export default injectIntl(SignupBanner);
