import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  signupTitle: {
    id: 'sign.up.title',
    defaultMessage: 'Vous désirez participer aux e-learning ?',
  },
  signupText: {
    id: 'sign.up.text',
    defaultMessage: 'Inscrivez-vous <strong>gratuitement</strong> pour accéder à tous les contenus de Mediquality sur tous vos écrans et recevoir notre Newsletter.',
  },
  signupButtonText: {
    id: 'sign.up.button.text',
    defaultMessage: ' S\'inscrire gratuitement',
  },
  signInButtonText: {
    id: 'sign.in.button.text',
    defaultMessage: ' J\'ai déjà un compte MediQuality',
  },
  noCourseText: {
    id: 'carrousel.empty.course',
    defaultMessage: "Aucun cours disponible pour l'instant",
  },
  discoverLearning: {
    id: 'discover.e.learning',
    defaultMessage: "Découvrir l'e-learning",
  },
  coursesStarted: {
    id: 'courses.started',
    defaultMessage: 'Commencés',
  },
  newsletterTitle: {
    id: 'newsletter.title',
    defaultMessage: 'Vous désirez être au courant des dernieres formations ?',
  },
  newsletterText: {
    id: 'newsletter.text',
    defaultMessage: 'Inscrivez-vous <strong>gratuitement</strong> à nos newsletters pour accéder à tous les contenus de Mediquality et ne rien louper !',
  },
  organizedBy: {
    id: 'course.organized.by',
    defaultMessage: 'Organisé par',
  },
});

export default messages;
