/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getStartedCourses, getLikedCourses } from './services';

export const LOADING = 'loading';
export const LOADED = 'loaded';
export const FAILED = 'failed';
export const DENIED = 'denied';

export const fetchWishlist = createAsyncThunk(
  'profile/fetchWishlist',
  async () => getLikedCourses(),
);

export const fetchStartedCourses = createAsyncThunk(
  'profile/fetchStartedCourses',
  async () => getStartedCourses(),
);

const courseSlice = createSlice(
  {
    name: 'courses',
    initialState: {
      courses: [],
      courseStatus: 'loading',
      likedCourses: [],
      startedCourses: [],
      error: {},
    },
    reducers: {
      fetchCoursesDenied: (state) => {
        state.courseStatus = DENIED;
      },
      fetchCoursesFailure: (state, { payload }) => {
        state.courseStatus = FAILED;
        state.error = payload;
      },
      fetchCoursesRequest: (state) => {
        state.courseStatus = LOADING;
      },
      fetchCoursesSuccess: (state, { payload }) => {
        state.courses = payload?.results;
        state.courseStatus = LOADED;
        state.error = {};
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchWishlist.fulfilled, (state, action) => {
          state.likedCourses = [...action.payload] || [];
        })
        .addCase(fetchStartedCourses.fulfilled, (state, action) => {
          state.startedCourses = [...action.payload] || [];
        });
    },
  },

);

export const {
  fetchCoursesDenied,
  fetchCoursesFailure,
  fetchCoursesRequest,
  fetchCoursesSuccess,
} = courseSlice.actions;

export const { reducer } = courseSlice;
