import React, { useContext, useEffect, useState } from 'react';
import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import PropTypes from 'prop-types';
import CourseCard from '@mediquality/frontend-component-course-card';
import { CardGrid } from '@edx/paragon';
import { AppContext } from '@edx/frontend-platform/react';
import messages from '../../../Pages/messages';
import { addToWishList, removeFromWishList } from '../../../data/services';

const AllCourses = ({ intl, courses, likedCourses }) => {
  const user = useContext(AppContext).authenticatedUser;
  const [likedCoursesList, setLikedCoursesList] = useState([]);
  useEffect(() => {
    setLikedCoursesList(likedCourses);
  }, [likedCourses]);

  const likeCourse = (courseId, courseBlocksUrl) => {
    if (likedCoursesList.some(likedCourse => likedCourse.courseId === courseId.courseId)) {
      setLikedCoursesList([...likedCoursesList.filter(course => course.courseId !== courseId.courseId)]);
      removeFromWishList(user.username, courseBlocksUrl);
    } else {
      setLikedCoursesList([...likedCoursesList, courses.find(course => course.courseId === courseId.courseId)]);
      addToWishList(courseId, courseBlocksUrl);
    }
  };
  const cardsPerPage = 6;
  const [next, setNext] = useState(cardsPerPage);
  const sliceCourses = courses.slice(0, next);
  const loadMore = () => {
    setNext(next + cardsPerPage);
  };

  return (
    <div className="homepage__all">
      {Object.keys(sliceCourses).length !== 0
        && (
        <h2 className="homepage__courses_category_title">
          {intl.formatMessage(messages.allCourseByMQ)}
        </h2>
        )}
      <div className="container d-flex justify-content-around mt-2 flex-wrap">
        <CardGrid columnSizes={{
          xs: 12,
          lg: 6,
          xl: 4,
        }}
        >
          {sliceCourses.map(course => (
            <CourseCard
              key={course.id}
              onDeleteClicked={() => {
              }}
              onDraftClicked={() => {
              }}
              onEditClicked={() => {
              }}
              onLikeClicked={courseId => likeCourse(courseId, course.blocksUrl)}
              id={course.id}
              title={course.name}
              followed={likedCoursesList?.some((likedCourse => likedCourse.courseId === course.id))}
              organizer={course.org}
              image={course.media?.image?.small}
              courseUrl={`${getConfig().LEARNING_BASE_URL}/course/${course.courseId}/home`}
              hidden={course.hidden}
              myCourse={false}
            />
          ))}
        </CardGrid>
        {courses && next < courses.length && (
          <button className="read-more-button" type="button" onClick={() => loadMore()}>
            {intl.formatMessage(messages.seeMore)}
          </button>
        )}

      </div>
    </div>
  );
};

AllCourses.propTypes = {
  intl: intlShape.isRequired,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  likedCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default injectIntl(AllCourses);
