import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tab, Tabs } from '@edx/paragon';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as toLike } from '@fortawesome/free-regular-svg-icons';
import { faHeart as liked } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '@edx/frontend-platform/react';

import TagButton from '../../generic/tag-button/TagButton';
import rightArrow from '../../../assets/images/right-arrow.svg';
import messages from '../../messages';
import { addToWishList, removeFromWishList } from '../../../data/services';

const CourseInfo = ({
  id, title, accreditation, description, logo, urlSrc, organisator, courseBlockUrl, isLiked, intl,
}) => {
  const user = useContext(AppContext).authenticatedUser;
  const [isLikedState, setIsLikedState] = useState(false);
  useEffect(() => {
    setIsLikedState(isLiked);
  }, [isLiked]);

  const handleAddOrRemoveToWishlist = (e, courseBlockUrl1) => {
    if (isLikedState) {
      removeFromWishList(user.username, courseBlockUrl1);
      setIsLikedState(false);
    } else {
      addToWishList(id, courseBlockUrl);
      setIsLikedState(true);
    }
  };

  return (
    <Card orientation="horizontal">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <TagButton content="e-learning" classname="blue-tag mt-5 ml-5" />
          <FontAwesomeIcon
            icon={isLikedState ? liked : toLike}
            className="mt-5 mr-5 icon-like"
            onClick={(e) => handleAddOrRemoveToWishlist(e, courseBlockUrl)}
          />
        </div>
        <Card.Section>
          <Tabs
            defaultActiveKey="Organized by"
            id="uncontrolled-pills-tab-example"
            variant="pills"
          >
            <Tab eventKey="Organized by" title={intl.formatMessage(messages.organizedBy)}>
              <a href={urlSrc}>
                <h2 className="homepage__sign-in_title"> {title} </h2>
              </a>
              <div className="description">{description}</div>
              <div className="discover-course">
                <a href={urlSrc}>
                  <span className="pr-2">
                    {intl.formatMessage(messages.discoverLearning)}
                  </span>
                  <img src={rightArrow} alt="..." />
                </a>
              </div>
            </Tab>
            <Tab eventKey="Organization" title={organisator}>
              {organisator}
            </Tab>
          </Tabs>
          {accreditation && (
          <div className="accreditation homepage__sign-in_title ">
            {accreditation}
          </div>
          )}
        </Card.Section>
      </Card.Body>
      <Card.ImageCap src={logo} />
    </Card>
  );
};

CourseInfo.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  accreditation: PropTypes.string,
  description: PropTypes.string.isRequired,
  logo: PropTypes.string,
  urlSrc: PropTypes.string,
  organisator: PropTypes.string,
  courseBlockUrl: PropTypes.string.isRequired,
  isLiked: PropTypes.bool,
  intl: intlShape.isRequired,
};

CourseInfo.defaultProps = {
  accreditation: '',
  logo: '',
  urlSrc: '',
  organisator: '',
  isLiked: false,
};

export default injectIntl(CourseInfo);
