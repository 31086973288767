import React from 'react';
import PropTypes from 'prop-types';
import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { useSelector } from 'react-redux';
import { Carousel, Spinner } from '@edx/paragon';
import CourseInfo from './course-info';
import messages from '../messages';

const CourseNews = ({ intl, courses, likedCourses }) => {
  const { courseStatus } = useSelector((state) => state.courses);

  const courseCards = !courses ? (
    <p>{intl.formatMessage(messages.noCourseText)}</p>
  ) : (
    courses.map((course) => (
      <Carousel.Item key={course.id}>
        <div className="news-carousel-item">
          <CourseInfo
            id={course.courseId}
            title={course.name}
            description={course.shortDescription}
            logo={course.media.image.small}
            urlSrc={`${getConfig().LEARNING_BASE_URL}/course/${course.courseId}/home`}
            organisator={course.org}
            courseBlockUrl={course.blocksUrl}
            isLiked={likedCourses.some((likedCourse => likedCourse.courseId === course.id))}
          />
        </div>
      </Carousel.Item>
    ))
  );
  return (
    <div className="container">
      <div className="col-9">
        {courseStatus === 'loading' ? (
          <Spinner />
        ) : (
          <Carousel indicators={false}>{courseCards}</Carousel>
        )}
      </div>
    </div>
  );
};

CourseNews.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  likedCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CourseNews);
