import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@edx/paragon';
import CourseCard from '@mediquality/frontend-component-course-card';
import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import messages from '../../messages';
import { chunkArray, calcPercent } from '../../../utils/utils.helper';

const StartedCourses = ({ intl, courses }) => {
  const chunkedCourses = chunkArray(courses, 2);
  const courseCards = !courses ? (
    <p>{intl.formatMessage(messages.noCourseText)}</p>
  ) : (
    chunkedCourses.map((courseList) => (
      <Carousel.Item key={`courseList-${courseList[0].course.details.id}`}>
        <div className="news-carousel-item d-flex justify-content-around">
          {courseList.map((course) => (
            <div key={course.course.details.id} className="pr-2 news-carousel-item_card">
              <CourseCard
                onDeleteClicked={() => {}}
                onDraftClicked={() => {}}
                onEditClicked={() => {}}
                onLikeClicked={() => {}}
                id={course.course.details.id}
                title={course.course.details.name}
                accreditationPoints={course.accreditation?.accreditationPoints}
                accreditationOrganism={course.accreditation?.accreditationOrganism}
                accreditationNumber={course.accreditation?.accreditationCode}
                followed={false}
                organizer={course.course.details.org}
                image={course.course.details.media?.image?.small}
                courseUrl={`${getConfig().LEARNING_BASE_URL}/course/${
                  course.course.details.id
                }/home`}
                progression={calcPercent(
                  course.progress.completionSummary.incompleteCount,
                  course.progress.completionSummary.completeCount,
                )}
                hidden={course.course.details.hidden}
                myCourse={false}
              />
            </div>
          ))}
        </div>
      </Carousel.Item>
    ))
  );
  return (
    <>
      <div className="homepage__news">
        <h2 className="homepage__courses_category_title">
          {intl.formatMessage(messages.coursesStarted)}
        </h2>
        <div className="container">
          <div className="col-9 justify-content-end offset-2 started-courses-carousel">
            <Carousel indicators={false}>{courseCards}</Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

StartedCourses.propTypes = {
  intl: intlShape.isRequired,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default injectIntl(StartedCourses);
