import { logError } from '@edx/frontend-platform/logging';
import { fetchCoursesFailure, fetchCoursesRequest, fetchCoursesSuccess } from './courseSlice';
import { getCourses } from './services';

// eslint-disable-next-line import/prefer-default-export
export const fetchCourses = () => async (dispatch) => {
  dispatch(fetchCoursesRequest());
  Promise.allSettled([getCourses()]).then(([res]) => {
    if (res.status === 'fulfilled') {
      dispatch(fetchCoursesSuccess(res.value));
    } else {
      dispatch(fetchCoursesFailure(res.reason));
      logError(res.reason);
    }
  });
};
