import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  welcomeSubtitle: {
    id: 'welcome.subtitle',
    defaultMessage: 'Bienvenue sur MediQuality Academy',
  },
  welcomeTitle: {
    id: 'welcome.title',
    defaultMessage: 'Découvrez notre plateforme de formation en ligne',
  },
  welcomeText: {
    id: 'logged.in.welcome.text',
    defaultMessage: 'Participez aux e-learnings <strong> accrédités ou non-accrédités </strong> afin de vous former au mieux dans votre pratique.',
  },
  subscribeMessage: {
    id: 'subscribe.message',
    defaultMessage: "Accéder à l'intégralité des e-learnings en vous inscrivant <strong> gratuitement </strong>",
  },
  subscribeButtonText: {
    id: 'subscribe.button.text',
    defaultMessage: "S'inscrire",
  },
  newsTitle: {
    id: 'logged.in.news.title',
    defaultMessage: 'Nouveautés',
  },
  allCourseByMQ: {
    id: 'all.course.by.mq',
    defaultMessage: 'Tous les cours offerts par MediQuality',
  },
  seeMore: {
    id: 'see.more',
    defaultMessage: 'Voir plus',
  },
});
export default messages;
