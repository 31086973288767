import React, { useContext } from 'react';
import { AppContext } from '@edx/frontend-platform/react';
import HomePage from './HomePage';

const Home = () => {
  const { authenticatedUser } = useContext(AppContext);
  const isLoggedIn = authenticatedUser !== null;
  return <HomePage isLoggedIn={isLoggedIn} />;
};

export default Home;
