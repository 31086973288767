import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@edx/paragon';
import classNames from 'classnames';

const TagButton = ({ content, classname }) => (
  <Button
    className={classNames('btn-tag', classname)}
    variant="tertiary"
    disabled
  >
    {content}
  </Button>
);
TagButton.defaultProps = {
  classname: '',
};
TagButton.propTypes = {
  content: PropTypes.string.isRequired,
  classname: PropTypes.string,
};
export default TagButton;
